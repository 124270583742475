@charset "utf-8";

@font-face {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu Mono'), local('UbuntuMono-Regular'), url(fonts/ubuntumono.woff2) format('woff2');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu'), url(fonts/ubuntu.woff2) format('woff2');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url(fonts/ubuntubold.woff2) format('woff2');
}

@font-face {
  font-family: 'Vazirmatn';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Vazirmatn%5Bwght%5D.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Droid Arabic Naskh';
  font-style: normal;
  font-weight: 700;
  src: local('Droid Arabic Naskh Bold'), local('Droid Arabic Naskh-Bold'), url(fonts/DroidNaskh-Bold.woff) format('woff');
}
